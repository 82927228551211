import Icon from "@mdi/react";
import { mdiTrayArrowDown } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { HeaderItemProfile } from "../HeaderItemProfile";
import { ItemNavInstructions } from "../menuItem/ItemNavInstructions";
import { MenuLanguage } from "../../menus/MenuLanguage";
import { MenuItems } from "../menuItem/MenuItems";
import { FaceIcon, LogoutOffset } from "../../svg";
import { useCustomNavigate } from "../../../hooks";
import { downloadPlatformApp, removeToken } from "../../../utils";
import { useCustomerStore } from "../../../store/customerStore";
import { IMenuItemsNavbar, ICustomerStore, SubMenu } from "../../../interfaces";
import HolaflyLogo from "../../../holafly_logo.svg";
import ReceiptIcon from "../../../assets/invoice/ReceiptIcon.svg";
import SendIcon from "../../../assets/referrals/SendIcon.svg";
import { CoinsInfo } from "./CoinsInfo";

export const NavbarDesktop = (): JSX.Element => {
  const { referralUser } = useCustomerStore((store: ICustomerStore) => store);
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { customerId, language, profile } = useCustomerStore(
    (store: ICustomerStore) => store
  );

  const menuItems: IMenuItemsNavbar[] = [
    {
      id: 1,
      name: t("global.customerV2.header.nav.item1"),
      path: `/${customerId ? customerId : "login"}`,
      isCustomElement: false,
    },
    {
      id: 2,
      name: "",
      path: "",
      isCustomElement: true,
      customElement: <ItemNavInstructions />,
    },
    {
      id: 3,
      name: t("global.customerV2.header.nav.item3"),
      path: `/helpCenter`,
      isCustomElement: false,
    },
  ];

  const subMenuProfile: SubMenu[] = [
    // {
    //   customSubMenu: (
    //     <div className="cursor-pointer">
    //       <ReferralsProfile padding={16} /> <hr />
    //     </div>
    //   ),
    //   icon: "",
    //   label: "",
    //   handleClick: () => navigate("/referralBoard"),
    // },
    {
      icon: <FaceIcon color="black" />,
      label: t("global.customerV2.header.menu.item1"),
      handleClick: () => navigate("/account"),
    },
    {
      icon: <img src={SendIcon} alt="send" />,
      label: t("global.customerV2.travelClub.title"),
      handleClick: () => navigate("/referralBoard"),
      isNewElement: true,
    },
    {
      icon: <img src={ReceiptIcon} alt="send" />,
      label: t("global.customerV2.header.menu.item4"),
      handleClick: () => navigate("/invoice"),
      addDivider: true,
    },
    {
      icon: <LogoutOffset color="black" />,
      label: t("global.customerV2.header.menu.item2"),
      handleClick: () => {
        const prevEmail = localStorage.getItem("email");
        localStorage.clear();
        removeToken();
        localStorage.setItem("email", prevEmail || "");
        navigate("/login");
      },
    },
  ];

  return (
    <>
      <a
        href={`/?language=${language}`}
        data-testid="btn_redirect"
        className="flex items-center"
      >
        <img
          draggable={false}
          className="hidden lg:block w-full h-[50px]"
          src={HolaflyLogo}
          alt="Holafly_logo_web"
        />
      </a>

      <MenuItems
        overrideStyles="hidden lg:flex"
        items={menuItems}
        selectedId={0}
      />

      <div className="hidden h-full lg:flex gap-[10px] items-center">
        <div
          data-testid="button-download-app"
          onClick={downloadPlatformApp}
          className="cursor-pointer py-3 px-5 gap-1 flex justify-between items-center h-[40px] hover:from-magenta-bold hover:to-magenta-bold bg-gradient-to-r from-magenta to-magenta-900 text-white rounded-xl"
        >
          <p className="w-full text-sm font-medium whitespace-nowrap">
            {t("global.customerV2.header.buttonDownload")}
          </p>
          <Icon path={mdiTrayArrowDown} size={1} color={"white"} />
        </div>

        <div className="hidden lg:block">
          <MenuLanguage />
        </div>
        <hr className="w-[1px] h-[12px] bg-slate-300 " />
        <CoinsInfo coins={referralUser?.points_balance || 0} />
        <hr className="w-[1px] h-[12px] bg-slate-300 " />
        <HeaderItemProfile
          name={profile?.firstName || ""}
          subMenu={subMenuProfile}
        />
      </div>
    </>
  );
};
