import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useTranslation } from "react-i18next";
import TabsBar from "../../components/tabsBar/TabsBar";
import Layout from "../../components/layouts/Layout";
import { SplashLoading } from "../../components/splash/SplashLoading";
import { ContentModal } from "../../components/modal/ContentModal";
import { TopupModal } from "./components/modals/TopupModal";
import { MainInitialBanner } from "../../components/mainInitialBanner/MainInitialBanner";
import { PendingTopupModal } from "./components/modals/PendingTopupModal";
import { SectionGallery } from "./components/SectionGallery";
import { SectionAnotherEsim } from "./components/SectionAnotherEsim";
import { BannerReferrals } from "./components/BannerReferrals";
import { ExpiredSectionOrDefault } from "./components/ExpiredSectionOrDefault";
import { CardViewWithPagination } from "./components/CardViewWithPagination";
import { useGetCustomerCards, useTopupAndPackages } from "../../hooks";
import { useCustomerStore } from "../../store/customerStore";
import { TABS } from "../../constants";
import { IOrderEsim, ITabOptions } from "../../interfaces";

import "./style.css";
import { isProviderActive } from "../../utils/validateProviders";

const Main = (): JSX.Element => {
  const { update }: any = useIntercom();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { emailLocalStorage, fetchReferralGetUser, profile } = useCustomerStore(
    (state) => state
  );

  const [tabsBarOptions, setTabsBarOptions] = useState<
    { active: boolean; id: string; label: string }[]
  >([
    {
      active: true,
      id: TABS.MY_SIMS,
      label: "global.customerV2.home.tabs.tab1",
    },
    {
      active: false,
      id: TABS.EXPIRED,
      label: "global.customerV2.home.tabs.tab2",
    },
  ]);

  const [optionTab, setOptionTab] = useState("MY_SIMS");
  const [dataDetail, setDataDetail] = useState({} as IOrderEsim);
  const [isModalError, setIsModalError] = useState<boolean>(false);
  const [isModalTopUp, setIsModalTopUp] = useState<boolean>(false);

  const { topups, loadingTopups, haveNextPackages } = useTopupAndPackages({
    idDetail: dataDetail.id,
    checkNextPackages: true,
  });

  const {
    data,
    loading,
    loadingCards,
    initPerPage,
    updatePage,
    updatePerPage,
  } = useGetCustomerCards({
    isIncludeProvider: false,
    status: optionTab,
  });

  const cardDetailId = new URLSearchParams(location.search).get("quickTopupId");

  const setQuickTopUp = () => {
    const dataDetail = data.cards.find((card) => card.id === cardDetailId);

    if (
      !dataDetail ||
      (dataDetail.status !== "ACTIVE" &&
        isProviderActive(dataDetail.provider_name)) ||
      !!haveNextPackages
    ) {
      return;
    }

    setDataDetail(dataDetail);
    setIsModalTopUp(true);
    navigate(`/`, { replace: true });
  };

  useEffect(() => {
    setQuickTopUp();
  }, [cardDetailId, data.cards]);

  const handleTabsBarChange = (tab: ITabOptions) => {
    updatePage(1);
    updatePerPage(9);

    setOptionTab(tab.id);

    const newTabsState = tabsBarOptions.map((currentTab) =>
      currentTab.id === tab.id
        ? { ...currentTab, active: true }
        : { ...currentTab, active: false }
    );

    setTabsBarOptions(newTabsState);
  };

  const showModalTopUp = (resp: IOrderEsim) => {
    if (!haveNextPackages || haveNextPackages === null) {
      setIsModalTopUp(true);
      setDataDetail(resp);
      return;
    }
    setIsModalError(true);
  };

  const closeModalTopup = () => setIsModalTopUp(false);

  const closeModalError = () => setIsModalError(false);

  useEffect(()=>{
    update({
      name: `${profile.firstName} ${profile.lastName}`,
      email: profile.email,
      customAttributes: { language_override: t("global.locale") },
    });
  },[profile, t, update])

  useEffect(() => {
    if (emailLocalStorage) {
      fetchReferralGetUser("RedeemedCoupon", false);
    }
  }, [emailLocalStorage]);

  useEffect(() => {
    sessionStorage.setItem("paginationPosition", String(0));
    sessionStorage.setItem("paginationInitialPosition", String(0));
  }, [optionTab]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <SplashLoading loader={loading} />
      ) : (
        <Layout>
          <MainInitialBanner
            title="global.customerV2.banners.bannerHeaderTitle"
            subTitle="global.customerV2.banners.bannerHeaderSubtitle"
            centralClickElement={
              <TabsBar
                options={tabsBarOptions}
                onChange={handleTabsBarChange}
              />
            }
          />

          <div className="w-full pt-[100px] pb-[50px] lg:px-16 px-3 flex flex-col items-center z-0">
            <CardViewWithPagination
              cards={data.cards}
              loading={loadingCards}
              showModalTopUp={showModalTopUp}
              initPerPage={initPerPage}
              pages_count={data.pages_count}
              updatePerPage={updatePerPage}
              updatePage={updatePage}
            />

            <ExpiredSectionOrDefault tabs={tabsBarOptions} cards={data.cards} />

            <SectionGallery />

            <BannerReferrals testId="banner-referrals" />

            {data.cards.length ? <SectionAnotherEsim /> : null}
          </div>
        </Layout>
      )}

      {haveNextPackages === null || haveNextPackages === false ? (
        <ContentModal isModal={isModalTopUp} toggleModal={closeModalTopup}>
          <TopupModal
            closeModal={closeModalTopup}
            loading={loadingTopups}
            data={{ topUps: topups, dataDetail }}
          />
        </ContentModal>
      ) : (
        <ContentModal isModal={isModalError} toggleModal={closeModalError}>
          <PendingTopupModal closeModal={closeModalError} />
        </ContentModal>
      )}
    </Fragment>
  );
};

export default Main;
