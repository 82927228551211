import { SVGProps } from "react";
const WhatsAppIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M8.162 17.207a.1.1 0 0 1 .077.01l.543.318a6.4 6.4 0 1 0-2.314-2.314l.316.542a.1.1 0 0 1 .01.077l-.466 1.711a.1.1 0 0 0 .123.123l1.71-.467ZM4.34 19.91a.2.2 0 0 1-.246-.246l.99-3.636a8 8 0 1 1 2.891 2.89l-3.635.992ZM9.113 8.247a2.71 2.71 0 0 1 .322-.003c.043.003.087.008.13.013.127.014.267.092.314.2.239.54.47 1.085.695 1.631.05.122.02.278-.075.43a3.487 3.487 0 0 1-.21.297c-.09.116-.285.33-.285.33s-.08.094-.049.211c.011.045.048.11.082.164l.047.076c.205.342.48.688.816 1.015.096.092.19.188.29.276.375.33.799.6 1.256.8l.004.002c.068.03.103.046.202.088.05.02.1.04.153.053a.28.28 0 0 0 .293-.104c.58-.702.632-.747.637-.747v.001a.385.385 0 0 1 .303-.101.414.414 0 0 1 .141.032c.425.194 1.12.497 1.12.497l.466.209c.078.038.15.126.152.212.003.054.008.14-.01.298a2.228 2.228 0 0 1-.151.587.925.925 0 0 1-.168.241c-.08.085-.17.162-.264.23a2.942 2.942 0 0 1-.1.073 4.04 4.04 0 0 1-.306.176c-.206.11-.434.172-.667.184-.148.008-.296.019-.445.011l-.454-.07a7.56 7.56 0 0 1-3.072-1.636c-.18-.16-.348-.33-.52-.501-.711-.708-1.249-1.472-1.575-2.194a2.776 2.776 0 0 1-.265-1.131 2.183 2.183 0 0 1 .451-1.344c.059-.075.114-.154.209-.244.102-.096.166-.147.235-.182a.769.769 0 0 1 .297-.08Z"
    />
  </svg>
)

export default WhatsAppIcon