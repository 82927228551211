import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { mdiArrowLeft } from "@mdi/js";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { HeaderLogin } from "./HeaderLogin";
import HButton from "../../../components/common/hbutton/HButton";
import { useCustomerStore } from "../../../store/customerStore";
import { postEmailVerification, postSignIn } from "../../../services";
import { ICustomerStore, IVerificationCode } from "../../../interfaces";
import { formatTime, setToken } from "../../../utils";
import OtpInput from "react-otp-input";
import { LinkText } from "../../../components/linkText/LinkText";
import { useDataLayer } from "../../../hooks";
import {
  VERIFICATION_CODE_TIMER,
  VERIFICATION_CODE_ERRORS,
} from "../../../constants";

export const VerificationCode = ({
  email,
  hideVerificationCode,
  setError,
}: IVerificationCode) => {
  const [resendErrors, setResendErrors] = useState(0);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [timer, setTimer] = useState<number>(VERIFICATION_CODE_TIMER);
  const { setDataLayer } = useDataLayer();

  const { language } = useCustomerStore((store: ICustomerStore) => store);
  const navigate = useNavigate();

  const signIn = async () => {
    setLoader(true);

    const { data, status, isLoading } = await postSignIn(
      email,
      verificationCode
    );

    if (status === 200) {
      setToken(data.token);
      localStorage.setItem("customerId", data.customerId);
      navigate(`/`, { replace: true });
      setDataLayer({
        event: "login",
        userId: data.customerId,
      });
    } else if (status === 500) {
      if (data.blocked) {
        setError(t(VERIFICATION_CODE_ERRORS.MORE_ATTEMPTS));
      } else {
        setError(t(VERIFICATION_CODE_ERRORS.RESEND_SECOND_WARNING));
      }
      setLoader(isLoading);
    } else {
      setError(t(VERIFICATION_CODE_ERRORS.INTERNAL_ERROR));
      setLoader(isLoading);
    }
  };

  const isDisabledVerificationCode = verificationCode.length !== 5;
  const buttonTextVerificationCode = loader ? "" : t("global.login.btnLogin");
  const buttonClassVerificationCode =
    verificationCode.length === 5
      ? !loader
        ? "bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold text-white"
        : "bg-neutral-200 text-neutral-400"
      : "bg-neutral-200 text-neutral-400";

  const loaderIconVerificationCode = loader ? (
    <div
      className="inline-block h-6 w-6 animate-spin rounded-full border-2 border-solid border-gray-300 border-r-gray-600 align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    />
  ) : (
    <></>
  );

  const handleSendVerification = async () => {
    setTimer(VERIFICATION_CODE_TIMER);
    const { isError } = await postEmailVerification(email, language);

    if (isError) {
      const error =
        resendErrors === 0
          ? VERIFICATION_CODE_ERRORS.RESEND_WARNING
          : VERIFICATION_CODE_ERRORS.RESEND_SECOND_WARNING;
      setError(t(error));
      setResendErrors((prev) => prev + 1);
    }
  };

  const decrementTimer = useCallback(() => {
    setTimer((prevTimer) => prevTimer - 1);
  }, []);

  useEffect(() => {
    let intervalId: null | NodeJS.Timer = null;
    if (timer > 0) {
      intervalId = setInterval(decrementTimer, 1000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [timer, decrementTimer]);

  return (
    <div
      data-testid="verification-code"
      className="relative flex flex-col h-screen w-full py-12 md:px-16 px-4 bg-white"
    >
      <HeaderLogin displayBackButton>
        <HButton
          type="button"
          name="button go back"
          className="h-12 w-12 p-3 flex justify-center items-center border rounded-xl border-neutral-300 hover:bg-slate-50"
          icon={mdiArrowLeft}
          onClick={hideVerificationCode}
        />
      </HeaderLogin>

      <div className="mt-6" />
      <div className="flex flex-col md:items-start py-[4.6rem]">
        <div>
          <h1 className="font-semibold text-base md:text-[22px] leading-[22px] text-center md:text-start mb-6">
            {t("global.login.verficationCode.title")}
          </h1>

          <div className="flex flex-col text-neutral-charcoal text-center md:text-start ">
            <p>
              <Trans
                i18nKey="global.customerV2.new-login.verificationCode.description"
                values={{ email }}
              />
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center my-6">
          <OtpInput
            inputType="text"
            value={verificationCode}
            onChange={setVerificationCode}
            numInputs={5}
            renderSeparator={<div className="w-2" />}
            shouldAutoFocus
            renderInput={(props) => <input {...props} />}
            containerStyle="flex justify-center"
            inputStyle="border rounded-xl p-4 !w-12 h-14 text-center text-base focus:outline-1 focus:outline-black  border-neutral-300"
          />

          <HButton
            onClick={signIn}
            name="button verification code"
            type="button"
            disabled={isDisabledVerificationCode}
            className={`${buttonClassVerificationCode} rounded-xl py-3 px-5 w-full h-12 border-none text-base font-medium outline-none mt-6`}
            text={buttonTextVerificationCode}
            icon={loaderIconVerificationCode}
          />
        </div>

        <div className=" flex flex-col justify-between place-items-center h-full">
          <div className="flex flex-col">
            <p className="text-base font-normal text-center md:text-start">
              {t("global.login.verficationCode.messageNoCode")}
            </p>

            <div
              className={`mt-3 mb-6 flex flex-row items-center justify-center md:justify-start gap-3`}
            >
              <p className="text-base font-bold text-neutral-charcoal">
                {formatTime(timer)}
              </p>
              <HButton
                type="button"
                name="button resend code verification"
                disabled={timer > 0}
                onClick={handleSendVerification}
                className={`font-medium text-[14px] border-none p-0 capitalize underline ${
                  timer <= 0 ? "text-neutral-charcoal" : "text-neutral-400"
                }`}
                text={t("global.login.verficationCode.btnReSend")}
              />
            </div>
            <p className="text-neutral-350 text-xs font-bold">
              <LinkText
                to={t("global.login.linkTerms")}
                className={`cursor-pointer`}
              >
                {t("global.login.verficationCode.textTerms")}
              </LinkText>
              {" · "}
              <LinkText
                to={t("global.login.linkPolitics")}
                className={`cursor-pointer`}
              >
                {t("global.login.verficationCode.textPolitics")}
              </LinkText>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
