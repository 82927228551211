import { Navigate } from "react-router-dom";
import { Login } from "../pages/login/Login";
import { getToken } from "../utils";

export const LoginRoute = (): JSX.Element => {
  const token = getToken();
  const customerId = localStorage.getItem("customerId");

  return (
    <>{token && customerId ? <Navigate to={`/`} /> : <Login />}</>
  );
};
