import { useTranslation } from "react-i18next";
import HButton from "../common/hbutton/HButton";
import timeWorker from "../../assets/invoice/time_worker.svg";
import { useNavigate } from "react-router-dom";

export const ReceiptStatusMessage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = () => navigate(`/`);

  return (
    <div
      data-testid="receipt-status-message"
      className="flex flex-col justify-center items-center gap-4">
      <img
        src={timeWorker}
        alt="time-worker"
        draggable={false}
        loading="lazy"
      />

      <div className="flex flex-col gap-[6px] justify-center items-center">
        <h2 className="text-neutral-charcoal font-bold text-xl text-center">
          {t("global.customerV2.invoice.alerts.alertPurchaseNoReceipts.title")}
        </h2>

        <p className="text-neutral-charcoal font-normal text-sm md:text-base text-center w-[260px]">
          {t(
            "global.customerV2.invoice.alerts.alertPurchaseNoReceipts.description"
          )}
        </p>
      </div>

      <HButton
        type="button"
        name="button back Esims"
        onClick={handleNavigation}
        containerStyles="w-auto border border-neutral-300 rounded-xl hover:bg-neutral-200 "
        className="px-5 py-3 text-neutral-charcoal text-sm font-medium flex justify-center items-center"
        text={t(
          "global.customerV2.invoice.alerts.alertPurchaseNoReceipts.textButton"
        )}
      />
    </div>
  );
};
