import { useState, useEffect, useRef } from "react";
import { getAllByCustomerId } from "../services";
import {
  IUseGetCustomerCards,
  IDataCustomerCards,
} from "../interfaces";
import { useCustomerStore } from "../store/customerStore";

export const useGetCustomerCards = ({
  page = 1,
  perPage = 9,
  isIncludeProvider = false,
  status='MY_SIMS'
}: IUseGetCustomerCards) => {
  const {customerId} = useCustomerStore(store => store)
  const [data, setData] = useState<IDataCustomerCards>({
    cards: [],
    count: 0,
    pages_count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [loadingCards, setLoadingCards] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [initPage, setInitPage] = useState(page);
  const [initPerPage, setInitPerPage] = useState(perPage);
  const [includeProvider, setInitIncludeProvider] = useState(isIncludeProvider);

  const isMountedRef = useRef(true);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const fetchCustomerCards = async () => {
    setLoadingCards(true);

    const { data, error } = await getAllByCustomerId(
      {
        customerId,
        page: initPage,
        perPage: initPerPage,
        isIncludeProvider: includeProvider,
        status: status === 'MY_SIMS' ? "active&status=activation_qr" :"finished",
      },
      signal
    );

    if (isMountedRef.current) {
      setLoading(false);
      setLoadingCards(false);
      setData(data);
      setError(error);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    fetchCustomerCards();

    return () => {
      isMountedRef.current = false;
      abortController.abort();
    };
  }, [customerId, initPage, initPerPage, includeProvider,status]);

  const updatePage = (newPage: number) => {
    setInitPage(newPage);
  };

  const updatePerPage = (newPerPage: number) => {
    setInitPerPage(newPerPage);
  };

  const updateIncludeProvider = (newIncludeProvider: boolean) => {
    setInitIncludeProvider(newIncludeProvider);
  };

  return {
    data,
    loading,
    error,
    loadingCards,
    initPage,
    initPerPage,
    includeProvider,
    updatePage,
    updatePerPage,
    updateIncludeProvider,
  };
};
